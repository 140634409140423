import { template as template_6ae447a071c145acbed0fb2b53dfa7fb } from "@ember/template-compiler";
const FKControlMenuContainer = template_6ae447a071c145acbed0fb2b53dfa7fb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
