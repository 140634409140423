import { template as template_6554aaa96f37451492dd5d78e2a4f664 } from "@ember/template-compiler";
const FKLabel = template_6554aaa96f37451492dd5d78e2a4f664(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
