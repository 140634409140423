import { template as template_f102662728d448f2a168c55bea749707 } from "@ember/template-compiler";
const WelcomeHeader = template_f102662728d448f2a168c55bea749707(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
